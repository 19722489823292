'use client';

import type { ResponsiveValue, BetterSystemStyleObject, SxProp } from '@primer/react';
import { useResponsiveValue, merge } from '@primer/react';

import type { BoxProps } from '@/lib/primer';
import { Box } from '@/lib/primer';
import type { ThemeVars } from '@/lib/primer/ThemeProvider';

type MaybeResponsiveValue<T> = T | ResponsiveValue<T>;

// Just extracted from:
// https://github.com/primer/react/blob/0841df69f34090768fe85ad36242a676023022e2/packages/react/src/PageLayout/PageLayout.tsx#L184
const verticalDividerVariants = {
  none: {
    display: 'none',
  },
  line: {
    display: 'block',
    width: 1,
    backgroundColor: 'border.default',
  },
  filled: {
    display: 'block',
    width: 8,
    backgroundColor: 'canvas.inset',
    boxShadow: (theme: ThemeVars) =>
      `inset -1px 0 0 0 ${theme.colors.border.default}, inset 1px 0 0 0 ${theme.colors.border.default}`,
  },
} as const satisfies Record<string, BetterSystemStyleObject>;

export type DividerVariant = keyof typeof verticalDividerVariants;

export interface DividerProps extends BoxProps, SxProp {
  variant?: MaybeResponsiveValue<DividerVariant>;
}

export const VerticalDivider = ({ variant = 'none', sx = {}, ...props }: DividerProps) => {
  const responsiveVariant = useResponsiveValue(variant, 'none');
  return (
    <Box
      {...props}
      sx={merge<BetterSystemStyleObject>(
        {
          height: '100%',
          position: 'relative',
          ...verticalDividerVariants[responsiveVariant],
        },
        sx
      )}
    />
  );
};
