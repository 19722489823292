import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/components/logo/style.module.scss");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/app/(dashboard)/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SuspenseOrAuthBoundary"] */ "/srv/app/packages/web-ui/src/components/SuspenseOrAuthBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VerticalDivider"] */ "/srv/app/packages/web-ui/src/components/VerticalDivider.tsx");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/lib/primer/index.ts");
